<template>
  <form id="form-checkout" method="post" @submit.prevent="generatePaymentToken">
    <div class="mb-3">

      <div class="card rounded border-0 shadow">
        <div class="bg-light p-4 text-center rounded-top">
          <h4 class="mb-0 card-title text-primary">Cartão de crédito</h4>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="form-checkout__cardholderName">Nome: <span
                  class="text-danger">*</span></label>
                <input name="name" id="form-checkout__cardholderName" type="text"
                       v-model="name"
                       class="form-control fw-bold" required=""
                       placeholder="Ex. João S Farias">
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="credit_card_number">
                  Número do cartão: <span class="text-danger">*</span>
                </label>
                <input type="text" autocomplete="off" id="form-checkout__cardNumber"
                       v-model="cardNumber" @change="identifyBrand"
                       class="form-control fw-bold" v-mask="'#### #### #### ####'"
                       required="" placeholder="0000 0000 0000 0000">
              </div>
            </div>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="form-label" for="credit_card_number">
                  Parcelas: <span class="text-danger">*</span>
                </label>
                <select id="form-checkout__installments" class="form-control fw-bold" v-model="installment">
                  <option value="" disabled>Preencha os dados do cartão.</option>
                  <option v-for="inst in listInstallments.installments" :value="inst.installment">
                    {{ inst.installment }} parcela{{inst.installment > 1 ? 's': ''}} de R$ {{ inst.currency }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label" for="credit_card_data_valid">Data de expiração: <span
                  class="text-danger">*</span></label>
                <input type="text" autocomplete="off" id="form-checkout__expirationDate"
                       class="form-control fw-bold" v-model="expiration"
                       required="" placeholder="MM/AAAA" v-mask="'##/####'">
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label" for="credit_card_cvv">Código de segurança: <span
                  class="text-danger">*</span></label>
                <input type="text" autocomplete="off" id="form-checkout__securityCode"
                       class="form-control fw-bold" v-model="securityCode"
                       required="" placeholder="CVV" v-mask="'###'">
              </div>
            </div>
            <div class="col-md-12" v-if="schedule.availabilityTime.avail_online">
              <br>
              <div class="mb-3">
                <div class="form-check">
                  <label class="form-check-label" for="flexCheckDefault">
                    Ao agendar você concorda com
                    <a target="_blank" href="https://policies.ubbimed.com.br/term-telemedicine.php"
                       class="text-primary">
                      Termos da teleconsulta.
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-grid">
                <button class="btn btn-primary" type="submit" id="form-checkout__submit">
                  Agendar consulta
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {mask} from "vue-the-mask";

export default {
  name: "CreditCardEF",
  directives: {
    mask,
  },
  data() {
    return {
      accountId: '074b7895c7e9a8fb504749544ce66fa8',
      msgErrorHashCreditCard: '',
      listInstallments: [],

      brand: null,
      securityCode: null,
      name: null,
      cardNumber: null,
      expiration: null,
      installment: null,
    }
  },
  created() {
    let script = 'https://cdn.jsdelivr.net/gh/efipay/js-payment-token-efi/dist/payment-token-efi-umd.min.js';
    let tag = document.head.querySelector(`[src="${script}"`);
    tag = document.createElement("script");
    tag.setAttribute("src", script);
    tag.setAttribute("type", 'text/javascript');
    document.head.appendChild(tag);
  },
  computed: {
    paymentMethod() {
      return this.$store.state.toSchedule.schedule.paymentMethod;
    },
    schedule() {
      return this.$store.state.toSchedule.schedule;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async identifyBrand() {
      try {
        const brand = await EfiPay.CreditCard
          .setCardNumber(this.cardNumber.replaceAll(' ', ''))
          .verifyCardBrand();

        this.brand = brand;

        await this.getInstallments();

        console.log("Bandeira: ", brand);
      } catch (error) {
        alert(error);
      }
    },
    async getInstallments() {
      try {
        const installments = await EfiPay.CreditCard
          .setAccount(this.accountId)
          .setEnvironment("production")
          .setBrand(this.brand)
          .setTotal(parseInt(this.schedule.cancelingPolicy.value.replaceAll('.', '')))
          .getInstallments();

        this.listInstallments = installments;

        console.log("Parcelas", installments);
      } catch (error) {
        alert(error);
      }
    },
    async generatePaymentToken() {
      try {
        let [month, year] = this.expiration.split('/');
        const result = await EfiPay.CreditCard
          .setAccount(this.accountId)
          .setEnvironment("production")
          .setCreditCardData({
            brand: this.brand,
            number: this.cardNumber,
            cvv: this.securityCode,
            expirationMonth: month,
            expirationYear: year,
            holderName: this.name,
            holderDocument: this.userAuth.user_cpf,
            reuse: false,
          })
          .getPaymentToken();

        const payment_token = result.payment_token;
        const card_mask = result.card_mask;

        this.$emit('onSubmit', {
          card_mask: card_mask,
          token: payment_token,
          installments: this.installment,
          expiration_month: month,
          expiration_year: year,
        });

        console.log("payment_token", payment_token);
        console.log("card_mask", card_mask);
      } catch (error) {
        console.log("Código: ", error.code);
        console.log("Nome: ", error.error);
        console.log("Mensagem: ", error.error_description);
      }
    }
  }
}
</script>

<style scoped>

</style>