<template>
  <div>
    <section class="bg-half-170 d-table w-100">
      <div class="container">
        <div class="row mt-5 align-items-center">
          <div class="col-lg-6 col-md-7">
            <div class="title-heading">
              <h1 class="heading mb-3">Agendamento realizado com <span class="text-primary">SUCESSO.</span></h1>
              <p class="para-desc text-muted">
                Quer ser lembrado de sua consulta ou remarcar direto no celular?
                Baixe o app da Ubbimed.
              </p>
              <div class="mt-4">
                <a href="https://apps.apple.com/br/app/ubbimed/id6743162312" target="_blank" class="btn btn-primary mt-2 me-2">
                  <i class="uil uil-apple"></i>
                  App Store
                </a>
                <a href="https://play.google.com/store/apps/details?id=br.com.ubbimed.app" target="_blank" class="btn btn-outline-primary mt-2">
                  <i class="uil uil-google-play"></i>
                  Play Store
                </a>
              </div>

              <div class="mt-3">
                <router-link to="/schedules" class="btn btn-primary mt-2 me-2">
                  Acessar minha conta
                </router-link>
              </div>

            </div>
          </div>

          <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div class="text-md-end text-center ms-lg-4">
              <img src="../../assets/images/app-ubbimed.png" class="img-fluid" alt="app Ubbimed"
                   style="transform: scaleX(-1)">
            </div>
          </div>
        </div>
      </div>
    </section>

      <div class="modal fade" id="ModalFeature" tabindex="-1" aria-labelledby="ModalFeature" aria-hidden="true">
          <div class="modal-dialog">
              <form method="post" @submit.prevent="onSubmit()">
                  <div class="modal-content rounded shadow border-0">
                      <div class="modal-header border-bottom">
                          <h5 class="modal-title">Lista de espera</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <div class="row">
                              <div class="col-md-12">
                                  <p>
                                      Nosso aplicativo está em fase final de desenvolvimento, mas não se preocupe!
                                      <br>
                                      Você pode se cadastrar na lista de espera, e avisaremos assim que estiver disponível para você.
                                  </p>
                              </div>
                              <div class="col-md-12">

                                  <div class="mb-3">
                                      <label class="form-label" for="user_name">Seu nome <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                               class="feather feather-user fea icon-sm icons">
                                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                              <circle cx="12" cy="7" r="4"></circle>
                                          </svg>
                                          <input name="user_name" id="patient_name"
                                                 type="text" v-model="form.user_name"
                                                 :class="errors.user_name ? 'is-invalid' : ''"
                                                 class="form-control ps-5" placeholder="Seu nome">
                                          <div class="invalid-feedback d-block" v-if="errors.user_name">
                                              {{ errors.user_name.join('\n') }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">Seu whatsapp <span class="text-danger">*</span></label>
                                      <div class="form-icon position-relative">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-sm icons"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                                          <input type="tel" class="form-control ps-5" placeholder="Seu número do whatsapp"
                                                 :class="errors.user_phone ? 'is-invalid' : ''"
                                                 v-mask="['(##) ####-####', '(##) #####-####']"
                                                 v-model="form.user_phone" required="">
                                          <div class="invalid-feedback d-block" v-if="errors.user_phone">
                                              {{ errors.user_phone.join('\n') }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <div class="mb-3">
                                      <label class="form-label">Seu e-mail </label>
                                      <div class="form-icon position-relative">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                          <input type="email" class="form-control ps-5" placeholder="Seu e-mail"
                                                 :class="errors.user_email ? 'is-invalid' : ''"
                                                 autocomplete="user_email" style="text-transform: lowercase;"
                                                 v-model="form.user_email" required="">
                                          <div class="invalid-feedback d-block" v-if="errors.user_email">
                                              {{ errors.user_email.join('\n') }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-md-12">
                                  <div class="mb-3">
                                      <label class="form-label" for="user_name">Sua cidade</label>
                                      <div class="form-icon position-relative">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                               class="feather feather-user fea icon-sm icons">
                                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                              <circle cx="12" cy="7" r="4"></circle>
                                          </svg>
                                          <input name="city_name" id="city_name"
                                                 type="text" v-model="form.city_name"
                                                 :class="errors.city_name ? 'is-invalid' : ''"
                                                 class="form-control ps-5" placeholder="Sua cidade">
                                          <div class="invalid-feedback d-block" v-if="errors.city_name">
                                              {{ errors.city_name.join('\n') }}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="modal-footer">
                          <div class="col-md-12">
                              <div class="d-grid gap-2">
                                  <button type="submit" :disabled="submitted" class="btn btn-outline-primary">
                                      Enviar
                                      <svg v-if="submitted" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="local-spin svg-inline--fa fa-spinner fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>

  </div>
</template>

<script>
// @ is an alias to /src
import MenuAction from './MenuAction.vue'
import http from "../../helpers/http";
import Swal from "sweetalert2";
import {mask} from 'vue-the-mask';
import Modal from "../../helpers/modal";

export default {
  name: 'Scheduled',
  components: {
    MenuAction
  },
    directives: {
        mask,
    },
    data() {
      return {
          errors: [],
          submitted: false,
          form: {
              user_name: '',
              user_email: '',
              feature: '',
              user_phone: '',
              city_name: '',
              id_user: '',
          }
      }
    },
    mounted() {
        history.pushState(null, null, window.location.href);
      let userAuth = this.$store.state.auth.user;
      this.form.id_user = userAuth.id_user;
      this.form.user_name = userAuth.user_name;
      this.form.user_phone = userAuth.user_phone;
      if (!userAuth.user_email.includes('@xxx'))
          this.form.user_email = userAuth.user_email;
    },
    methods: {
        openModalFeature(feature) {
            this.form.feature = feature;

            Modal.toggle('#ModalFeature');
        },
        onSubmit() {
            http.post(`/feature-waiting-lists`, this.form).then(() => {
                Modal.toggle('#ModalFeature');
                Swal.fire({
                    title: 'Aeeeh!',
                    text: 'Você se cadastrou na lista de espera com sucesso!\nAvisaremos assim que o aplicativo estiver disponível.',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonText: 'Acessar minha conta',
                    confirmButtonColor: '#780f5a',
                }).then(() => {
                    this.$router.push('/schedules')
                })
            }).finally(() => {
                this.submitted = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
