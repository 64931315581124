<template>
  <section class="section-two bg-light mt-5 mb-2">
    <NoResult v-if="schedule.professional == null"/>

    <div class="container-fluid" v-else>
      <div class="row m-3">

        <div class="col-lg-3 mb-4 order-2 order-sm-1 order-md-1">
          <MenuAction/>
        </div>

        <div class="col-lg-9 order-1 order-sm-2 order-md-2">

          <div class="row">

            <div :class="paymentMethod?.is_credit_card ? 'col-md-12' : 'col-md-10 offset-md-1'">
              <div class="alert alert-danger" role="alert" v-if="msgErrorSchedule">
                {{ msgErrorSchedule }}
              </div>
            </div>

            <div class="col-md-6" v-if="paymentMethod?.is_credit_card">
              <CreditCardMP @onSubmit="onSubmitFormCreditCard"
                            v-if="cancelingPolicy.card_administrator_code == 'MP'"/>

              <CreditCardGN @onSubmit="onSubmitFormCreditCard"
                            v-else-if="cancelingPolicy.card_administrator_code == 'GN'"/>

              <CreditCardEF @onSubmit="onSubmitFormCreditCard"
                            v-else-if="cancelingPolicy.card_administrator_code == 'EF'"/>

              <div v-else class="text-center">
                <h3>Falha ao carregar dados, tente novamente</h3>
                <button class="btn btn-primary" type="button" @click="window.location.reload()">
                  Tentar novamente
                </button>
              </div>
            </div>

            <div :class="paymentMethod?.is_credit_card ? 'col-md-6' : 'col-md-10 offset-md-1'" class="mb-3">
              <div class="rounded shadow-lg p-4 sticky-bar" v-if="professional">
                <div class="d-flex mb-0 justify-content-between">
                  <h5>Confirme os dados</h5>
                </div>
                <div class="table-responsive">
                  <table class="table table-center table-padding mb-0">
                    <tbody>
                    <tr>
                      <td colspan="12" class="pb-0">
                        <p class="h6">Profissional: </p>
                        <p class="fw-bold pb-0">{{ professional.prof_name }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="12" class="pb-0">
                        <p class="h6">Local: </p>
                        <p class="fw-bold pb-0">{{ professional.place_name }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="12" class="pb-0">
                        <p class="h6">Endereço: </p>
                        <p class="fw-bold pb-0">
                          {{ professional.place_address }}, {{ professional.place_addr_numer }}
                          {{ professional.place_neighborhood }} -
                          {{ professional.city_name }}/{{ professional.state_initials }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="12" class="pb-0">
                        <p class="h6">Data: </p>
                        <p class="fw-bold pb-0">
                          {{ date(availability.available_date_patient).format('DD MMM YYYY - dddd') }} às
                          {{ availability.available_time_patient }}h
                        </p>
                      </td>
                    </tr>
                    <tr class="bg-light" v-if="cancelingPolicy">
                      <td colspan="12" class="pb-0">
                        <p class="h6">Valor: </p>
                        <p class="text-primary pb-0 h5 fw-bold">R$ {{ cancelingPolicy.value }}</p>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                  <div class="col-md-12" v-if="schedule.availabilityTime.avail_online">
                    <br>
                    <div class="mb-3">
                      <div class="form-check">
                        <label class="form-check-label" for="flexCheckDefault">
                          Ao agendar você concorda com
                          <a target="_blank" href="https://policies.ubbimed.com.br/term-telemedicine.php"
                             class="text-primary">
                            Termos da teleconsulta.
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 pt-2" v-if="!paymentMethod?.is_credit_card">
                    <div class="d-grid">
                      <button class="btn btn-primary" type="button" @click="scheduleAppointment">
                        Agendar consulta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="modalAddress" tabindex="-1" data-bs-keyboard="false" data-bs-backdrop="static"
           aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <form @submit.prevent="scheduleAppointment">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Confirme o seu endereço</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="address_cep" class="form-label required">
                        CEP <i class="text-danger">*</i>
                      </label>
                      <input type="text" class="form-control" id="address_cep"
                             @change="getZipCode($event.target.value)" required
                             v-model="userAddress.user_addr_zipcode">
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-3">
                      <label for="address" class="form-label required">
                        Endereço <i class="text-danger">*</i>
                      </label>
                      <input type="text" class="form-control" id="address" required
                             v-model="userAddress.user_address">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-3">
                      <label for="address_nro" class="form-label required">
                        Número <i class="text-danger">*</i>
                      </label>
                      <input type="text" class="form-control" id="address_nro" required
                             v-model="userAddress.user_addr_numer">
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="mb-3">
                      <label for="address_neighbourhood" class="form-label required">
                        Bairro <i class="text-danger">*</i>
                      </label>
                      <input type="text" class="form-control" id="address_neighbourhood" required
                             v-model="userAddress.user_neighborhood">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label for="address_complement" class="form-label">
                        Complemento
                      </label>
                      <input type="text" class="form-control" id="address_complement"
                             v-model="userAddress.user_addr_compl">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="address_stage" class="form-label">
                        Estado <i class="text-danger">*</i>
                      </label>
                      <InputSelect :required="true"
                                   id="address_stage"
                                   label="state_name"
                                   @change="getCitiesFromState($event)"
                                   value="id_state"
                                   :options="states"
                                   v-model="userAddress.id_state"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label for="address_city" class="form-label">
                        Cidade <i class="text-danger">*</i>
                      </label>
                      <InputSelect id="address_city" :required="true"
                                   label="city_name"
                                   value="id_city"
                                   :options="citiesFromState"
                                   v-model="userAddress.id_city"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="col-md-12">
                  <div class="d-grid">
                    <button type="submit" class="btn btn-primary">
                      Agendar consulta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import MenuAction from './MenuAction.vue'
import CreditCardMP from './gateways/CreditCardMP.vue'
import CreditCardGN from "./gateways/CreditCardGN.vue";
import date from "@/helpers/date";
import {mask} from 'vue-the-mask';
import Alert from "../../helpers/alert";
import Swal from "sweetalert2";
import NoResult from "./NoResult.vue";
import InputSelect from "../components/InputSelect.vue";
import http from "../../helpers/http";
import CreditCardEF from "./gateways/CreditCardEF.vue";

export default {
  name: 'Payment',
  components: {
    CreditCardEF,
    InputSelect,
    CreditCardGN,
    MenuAction,
    CreditCardMP,
    NoResult,
  },
  directives: {
    mask,
  },
  data() {
    return {
      msgErrorSchedule: '',
      creditCard: null,
      states: null,
      citiesFromState: null,
      userAddress: {
        user_address: null,
        user_addr_zipcode: null,
        user_addr_numer: null,
        user_addr_compl: null,
        user_neighborhood: null,
        id_city: null,
        id_state: null,
      },
    };
  },
  mounted() {
    this.getStates();

    this.userAddress = {
      user_address: this.userAuth.user_address,
      user_addr_zipcode: this.userAuth.user_addr_zipcode,
      user_addr_numer: this.userAuth.user_addr_numer,
      user_addr_compl: this.userAuth.user_addr_compl,
      user_neighborhood: this.userAuth.user_neighborhood,
      id_city: this.userAuth.id_city,
      id_state: this.userAuth.id_state,
    };

    if (this.userAuth.id_state) {
      this.getCitiesFromState(this.userAuth.id_state);
    }

  },
  computed: {
    userAuth() {
      return this.$store.state.auth.user;
    },
    schedule() {
      return this.$store.state.toSchedule.schedule;
    },
    paymentMethod() {
      return this.$store.state.toSchedule.schedule.paymentMethod;
    },
    professional() {
      return this.$store.state.toSchedule.schedule.professional;
    },
    availability() {
      return this.$store.state.toSchedule.schedule.availabilityTime;
    },
    cancelingPolicy() {
      return this.$store.state.toSchedule.schedule.cancelingPolicy;
    },
  },
  methods: {
    date: date,
    getStates() {
      http.get(`/states`).then((response) => {
        this.states = response.data;
      });
    },
    getCitiesFromState(stateId) {
      return http.get(`/states/${stateId}/cities`).then((response) => {
        this.citiesFromState = response.data;

        return response;
      });
    },
    getZipCode(zipCode) {
      http.get(`/zipcode/${zipCode}`).then((response) => {
        let data = response.data;
        this.userAddress.user_address = data.address;
        this.userAddress.user_neighborhood = data.neighborhood;
        let state = data.state;
        this.userAddress.id_state = state?.id_state;
        this.getCitiesFromState(state?.id_state);
        this.userAddress.id_city = data.city?.id_city;
      });
    },
    onSubmitFormCreditCard(creditCard) {
      this.creditCard = creditCard;

      let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modalAddress'));
      modal.toggle();
    },
    scheduleAppointment() {
      this.msgErrorSchedule = '';
      if (this.paymentMethod.is_credit_card && !this.creditCard) {
        Alert.error('Falha ao processar os dos cartão de crédito - Verifique sua conexão e tente novamente.');

        return;
      }

      if (this.paymentMethod.is_credit_card) {
        let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modalAddress'));
        modal.toggle();
      }

      Swal.fire({
        title: 'Agendando',
        text: 'Por favor, aguarde',
        allowOutsideClick: false,
      });
      Swal.showLoading();

      this.$store.dispatch('toSchedule/scheduleAppointment', {
        creditCard: this.creditCard,
        userAddress: this.userAddress
      }).then(() => {
        this.creditCard = null;
        if (this.paymentMethod?.is_pix) {
          this.$router.push('/pix');
        } else {
          this.$router.push('/scheduled');
        }

        Swal.close();
      }).catch(e => {
        this.msgErrorSchedule = e.response?.data;
        Swal.fire({
          icon: 'error',
          title: 'Falha ao agendar consulta',
          text: this.msgErrorSchedule,
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
