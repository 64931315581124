import AttendanceTypeToSchedule from "../views/toSchedule/AttendanceType";
import CitiesSupportedToSchedule from "../views/toSchedule/CitiesSupported";
import SpecialtiesToSchedule from "../views/toSchedule/Specialties";
import PaymentMethodsToSchedule from "../views/toSchedule/PaymentMethod";
import InsuranceToSchedule from "../views/toSchedule/Insurance";
import ProfessionalsToSchedule from "../views/toSchedule/Professionals";
import LoginSchedule from "../views/toSchedule/Login";
import RegisterSchedule from "../views/toSchedule/Register";
import PatientToSchedule from "../views/toSchedule/Patient";
import CancelingPolicyToSchedule from "../views/toSchedule/CancelingPolicy";
import PaymentToSchedule from "../views/toSchedule/Payment";
import ScheduledToSchedule from "../views/toSchedule/Scheduled";
import PixFailToSchedule from "../views/toSchedule/PixFail";
import ProfessionalIndex from "../views/toSchedule/professional/Index";
import ProfessionalPaymentMethod from "../views/toSchedule/professional/PaymentMethod";
import ProfessionalInsurance from "../views/toSchedule/professional/Insurance";
import Pix from "../views/toSchedule/Pix.vue";
import PreLoginRegister from "../views/toSchedule/PreLoginRegister.vue";
import ExamPreparation from "../views/toSchedule/ExamPreparation.vue";
import RememberPayment from "../views/toSchedule/RememberPayment.vue";

const routes = [
    {
        path: '/',
        name: 'AttendanceTypeToSchedule',
        component: AttendanceTypeToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/place/:id',
        name: 'PlaceAttendanceTypeToSchedule',
        component: AttendanceTypeToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/cities',
        name: 'CitiesSupportedToSchedule',
        component: CitiesSupportedToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/specialties',
        name: 'SpecialtiesToSchedule',
        component: SpecialtiesToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/payment-methods',
        name: 'PaymentMethodsToSchedule',
        component: PaymentMethodsToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/insurances',
        name: 'InsuranceToSchedule',
        component: InsuranceToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/professionals',
        name: 'ProfessionalsToSchedule',
        component: ProfessionalsToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/schedule/login',
        name: 'LoginSchedule',
        component: LoginSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/schedule/pre-signin',
        name: 'PreLoginRegister',
        component: PreLoginRegister,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/schedule/register',
        name: 'RegisterSchedule',
        component: RegisterSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/schedule/patients',
        name: 'PatientToSchedule',
        component: PatientToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/canceling-policies',
        name: 'CancelingPolicyToSchedule',
        component: CancelingPolicyToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/payment',
        name: 'PaymentToSchedule',
        component: PaymentToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/pix',
        name: 'PixToSchedule',
        component: Pix,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/schedules/:id/payment',
        name: 'RememberPayment',
        component: RememberPayment,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/scheduled',
        name: 'ScheduledToSchedule',
        component: ScheduledToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/pix-fail',
        name: 'PixFailToSchedule',
        component: PixFailToSchedule,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/professional/:professionalId',
        name: 'ProfessionalIndex',
        component: ProfessionalIndex,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/professional/:professionalId/payment-methods',
        name: 'ProfessionalPaymentMethod',
        component: ProfessionalPaymentMethod,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/professional/:professionalId/insurances',
        name: 'ProfessionalInsurance',
        component: ProfessionalInsurance,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
    {
        path: '/exam-preparation/:infos',
        name: 'ExamPreparation',
        component: ExamPreparation,
        meta: {
            auth: false,
            scheduling: true,
            layout: 'guest-layout'
        }
    },
];

export default routes